import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { MaterialModule } from '../../../core/material-module';
import { Router } from '@angular/router';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';
import { utility } from '../../../util';
import { WeldService } from '../../../APIServices/WeldService';
import { LookupService } from '../../../APIServices/LookupService';


@Component({
  selector: 'app-resource-list',
  standalone: true,
  imports: [DevExtremeModule, MaterialModule, FormsModule, ToolbarButtonsComponent],
  templateUrl: './resource-list.component.html',
  styleUrl: './resource-list.component.scss'
})
export class ResourceListComponent implements OnInit{
  title: any = "Resource Management";
  resourceModelList: any[] = [];
  resourceOriginalModelList: any[] = [];
  resourceOptionList: any[] = [];
  selectedResource: any;
  selectedResourceFilter: any = "";
  selectedInfo: any;
  uOriginalModelList: any[];
  loggedInUser: any;

  constructor(
    private weldService: WeldService,
    private router: Router,
    private lookupService: LookupService
  ) {}


  async ngOnInit() {
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    await this.index();
    await this.populateResourceOptionList();
  }

  async populateResourceOptionList() {
    (await this.lookupService.getResourceTypeList()).subscribe((apiResponse: any) => {
      this.resourceOptionList = utility.extractJsonData(apiResponse, 0);
      //this.resourceOptionList.push(this.sNew);

      if (this.resourceOptionList != null && this.resourceOptionList.length > 0)
        this.selectedResource = this.resourceOptionList[0].resource_type_id;
    });
  }

  public async index() {
    (await this.weldService.getAllResourceList(this.loggedInUser.logon_id, this.loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.resourceModelList = utility.extractJsonData(apiResponse, 0);
      this.resourceOriginalModelList = this.resourceModelList;
      this.filterByResourceType();
    });
  }


  filterByResourceType() {
    this.resetFilter();
    this.resourceModelList = this.resourceModelList.filter((v) => 
      v.res_type_id === (this.selectedResource == 0 ? null : this.selectedResource) 
    );
    
  }
  resetFilter() {
    this.resourceModelList = this.resourceOriginalModelList;
  }

  onSelectionChanged(e) {
    this.selectedInfo = e.selectedRowsData;
    this.weldService.setSelectedInfo(e.selectedRowsData);
  }

  routeToResourceDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./resourcedetails']);
    }
    let params = {
      site_id: e.row?.data.site_id,
      is_active: e.row?.data.is_active
    };
    this.router.navigate(['./resourcedetails'], { queryParams: params });
    var resourceModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      resourceModel = <any>JSON.parse(localUser);
      resourceModel.site_id = this.loggedInUser.site_id;
      resourceModel.site_code = this.loggedInUser.site_code;
      resourceModel.site_description = this.loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      resourceModel = <any>JSON.parse(localUser);
    }
    this.weldService.setSelectedInfo(resourceModel);
    e.event?.preventDefault();
  };

  addResource(){
    this.weldService.setSelectedInfo(null);
    this.router.navigate(['./resourcedetails']);
  }

  async deleteResource() {
    if (this.selectedInfo != null && this.selectedInfo.length == undefined) {
      alert("Please select a Resource");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected Resource?")) {
      return;
    }

    var resourceInfoDelete = {
      resource_list: this.selectedInfo,
      site_id: this.loggedInUser.site_id
    };

    (await this.weldService.deleteResource(resourceInfoDelete)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.index();
    });
  }

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Resource', path: '', iconName: '', action: () => this.addResource(), hidden: false },
   /*  { label: 'Inactivate Resource', path: '', iconName: '', action: () => this.deleteResource(), hidden: false }, */
  ];
}
