import { Component, OnInit, ViewChild } from '@angular/core';
import { utility } from '../../../util';
import { DevExtremeModule } from '../../../core/devExtreme.module'
import { Router } from '@angular/router';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { GroupService } from '../../../APIServices/GroupService';
import { groupModel, groupTreeModel, groupUserModel } from '../../../models/groupModel';
import { DxTreeListTypes } from 'devextreme-angular/ui/tree-list';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';
@Component({
  selector: 'app-group-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent],
  templateUrl: './group-list.component.html',
  styleUrl: './group-list.component.scss'
})
export class GroupListComponent implements OnInit {
  dModelList: any[] = [];
  selectedInfo: any;
  title: any = "Group Management";
  constructor(private groupService: GroupService, private router: Router) {

  }
  ngOnInit(): void {
    this.Index();
  }
  public async Index() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.groupService.getAllGroupList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
    });
  }
  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./groupdetails']);
    }

    var selectedID = e.row?.data.group_id;

    let params = {
      group_id: selectedID
    };
    this.router.navigate(['./groupdetails'], { queryParams: params });
    var dModel: any
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      dModel = <any>JSON.parse(localUser);

      var localUser = JSON.stringify(localStorage.getItem('loggedInUser'));
      var loggedInUser = JSON.parse(JSON.parse(localUser));
      dModel.site_id = loggedInUser.site_id;
      dModel.site_code = loggedInUser.site_code;
      dModel.site_description = loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      dModel = <any>JSON.parse(localUser);
    }
    this.groupService.setSelectedInfo(dModel);
    e.event?.preventDefault();
  };

  RouteToCreate() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var dModel;
    if (loggedInUser.site_id != 1) {
      dModel = {
        site_id: loggedInUser.site_id,
        site_code: loggedInUser.site_code,
        site_description: loggedInUser.site_description
      };
    }
    this.groupService.setSelectedInfo(dModel);
    this.router.navigate(['./groupdetails']);
  }
  onSelectionChanged(e) {
    this.selectedInfo = e.component.getSelectedRowsData('All');
    this.groupService.setSelectedInfo(this.selectedInfo)
  }

  editorPreparing(e: DxTreeListTypes.EditorPreparingEvent) {
    if (e.dataField === 'Head_ID') {
      e.cancel = true;
    }
  }


  allowEditing = ({ row }) => row.data.group_id !== 0;
  allowDelete = ({ row }) => row.data.group_id !== 0;

  //DeleteUserOrGroup = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
  async DeleteGroup() {
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a group");
      return;
    }
    var groupList = this.selectedInfo.filter((v) => {
      return (v.gu_id==0 && v.group_id!=0);
    });

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    let params = {
      site_id: groupList[0].site_id,
      groupList: groupList,
      requester_id: loggedInUser.logon_id
    };

    (await this.groupService.deleteGroup(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
   
  };

  async deleteGroupUserMapping() {
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select an user");
      return;
    }
    if (!confirm("Are you sure to delete the user from the group?")) {
      return;
    }

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    let params = {
      site_id: loggedInUser.site_id,
      guList: this.selectedInfo,
      requester_id: loggedInUser.logon_id
    };

    (await this.groupService.deleteUserGroupMapping(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }

  onCellPrepared(e) {
    if (e.rowType === "data") {
      if (e.data.head_id === 0) {
        e.cellElement.classList.add("dx-tree-cell-format");
      }
    }
  }
  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Group', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false },
    { label: 'Inactivate Group', path: '', iconName: '', action: () => this.DeleteGroup(), hidden: false },
    { label: 'Remove User', path: '', iconName: '', action: () => this.deleteGroupUserMapping(), hidden: false },
  ];
}
