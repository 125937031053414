import { Component } from '@angular/core';
import { siteModel } from '../../../models/siteModel';
import { SiteService } from '../../../APIServices/SiteService';
import { MsalService } from '@azure/msal-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../util';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MaterialModule } from '../../../core/material-module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-site-details',
  standalone: true,
  imports: [MaterialModule, FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './site-details.component.html',
  styleUrl: './site-details.component.scss'
})
export class SiteDetailsComponent {
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean = false;
  sForm: FormGroup;

  constructor(private route: ActivatedRoute, private siteService: SiteService
    , private msalservice: MsalService, private router: Router) {
    this.initializeForm(null);
  }

  async ngOnInit() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    await this.siteService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {
      this.queryParams = this.route.snapshot.queryParams['site_id'];
      if (this.queryParams != undefined) {
        this.bEditMode = true;
        (await this.siteService.getSelectedSIte(loggedInUser.logon_id, this.queryParams)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];
          console.log(this.selectedInfo);
          this.siteService.setSelectedInfo(this.selectedInfo);
          this.initializeForm(this.selectedInfo);
        });
      }
      else {
        if (loggedInUser.site_id == 1 || loggedInUser.site_code == 'GLB') {
          this.bEditMode = false;
        }
      }
    }
    else {
      this.bEditMode = true;
      await this.initializeForm(this.selectedInfo);
    }
  }

  backtoList() {
    this.router.navigate(['./sitelist']);
  }

  async save() {
    this.sForm.markAllAsTouched();
    const isFormValid = this.sForm.valid;
    if (!isFormValid) {
      return;
    }

    if (this.sForm.value['site_id'] == null || this.sForm.value['site_id'] == '') {
      this.createSite();
    } else {
      this.updateSite();
    }
  }

  async createSite() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.sForm.value['requester_id'] = loggedInUser.logon_id;

    (await this.siteService.createSite(this.sForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }

  async updateSite() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    this.sForm.value['requester_id'] = loggedInUser.logon_id;

    (await this.siteService.updateSite(this.sForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }

  validateInput(): boolean {
    var errorMsg: string = "";

    if (this.selectedInfo == null) {
      alert('Invalid input. Please check');
      return false;
    }
    for (let key of Object.keys(this.selectedInfo)) {
      if (this.selectedInfo[key] != null) {
        this.selectedInfo[key] = utility.removeUnknownCharLoop(this.selectedInfo[key])
      }
    }

    if (this.selectedInfo.site_code == null || this.selectedInfo.site_code == undefined
      || this.selectedInfo.site_code == '') {

      errorMsg = errorMsg !== "" ? (errorMsg + "\n") : errorMsg
      errorMsg += 'Please input Site Code';
    }
    if (this.selectedInfo.site_description == null || this.selectedInfo.site_description == undefined
      || this.selectedInfo.site_description == '') {

      errorMsg = errorMsg !== "" ? (errorMsg + "\n") : errorMsg
      errorMsg += 'Please input Site Description';
    }

    if ((this.selectedInfo.site_id != null && this.selectedInfo.site_id != undefined
      && this.selectedInfo.site_id != 0)
      && (this.selectedInfo.site_code == null || this.selectedInfo.site_code == undefined
        || this.selectedInfo.site_code == '')) {

      errorMsg = errorMsg !== "" ? (errorMsg + "\n") : errorMsg
      errorMsg += 'Please provide Site code';
    }
    if (errorMsg != "") {
      alert(errorMsg);
      return false;
    }

    return true;
  }
  async initializeForm(sInfo: any) {
    if (sInfo != null) {
      this.sForm = new FormGroup({
        site_id: new FormControl(sInfo?.site_id),
        site_code: new FormControl({ value: sInfo?.site_code, disabled: this.bEditMode }, [Validators.required]),
        site_description: new FormControl(sInfo?.site_description, [Validators.required]),
        time_zone: new FormControl(sInfo?.time_zone, [Validators.required]),
        uom: new FormControl(sInfo?.uom, [Validators.required]),
        base_folder: new FormControl(sInfo?.base_folder),
        global_folder_path: new FormControl(sInfo?.global_folder_path),
        is_active: new FormControl({ value: sInfo?.is_active, disabled: sInfo?.is_active }),
        requester_id: new FormControl('')
      });
    }
    else {
      this.sForm = new FormGroup({
        site_id: new FormControl(null),
        site_code: new FormControl({ value: '', disabled: this.bEditMode }, [Validators.required]),
        site_description: new FormControl('', [Validators.required]),
        time_zone: new FormControl('', [Validators.required]),
        uom: new FormControl('', [Validators.required]),
        base_folder: new FormControl(''),
        global_folder_path: new FormControl(''),
        is_active: new FormControl({ value: true, disabled: true }),
        requester_id: new FormControl('')
      });
    }
  }
}
