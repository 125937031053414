import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectJobService } from '../../../APIServices/ProjectJobService';
import { CommonModule } from '@angular/common';
import { utility } from '../../../util';
import { MaterialModule } from '../../../core/material-module';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-edit-job',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule],
  templateUrl: './add-edit-job.component.html',
  styleUrl: './add-edit-job.component.scss'
})
export class AddEditJobComponent implements OnInit {
  pJobForm: FormGroup;
  pJobValidation: any[];
  activeStatus = utility.activeStatus;
  selectedInfo: any;
  isEditMode:any;

  constructor(private dialogRef: MatDialogRef<AddEditJobComponent>,
    private router: Router, private projectJobService: ProjectJobService
  ) {
    this.initializeForm(null);
  }
  async ngOnInit() {
    await this.projectJobService.getSelectedInfo.subscribe(x =>
      this.selectedInfo = x);
    
      if (this.selectedInfo.job_id == null || this.selectedInfo.job_id == 0
        || this.selectedInfo.job_id == undefined) {
        this.isEditMode = false;
      } else {
        this.isEditMode = true;
      }  

    if (this.selectedInfo != null) {
      this.initializeForm(this.selectedInfo);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  saveJobInfo() {
    this.pJobForm.markAllAsTouched();
    const isFormValid = this.pJobForm.valid;
    if (!isFormValid) {
      return;
    }
    if (this.pJobForm.value['job_id'] == null) {
      this.createJob();
    }
    else {
      this.updateJob();
    }
  }
  async createJob() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //this.pJobForm.value['requester_id'] = loggedInUser.logon_id;
    this.pJobForm.controls['requester_id'].setValue(loggedInUser.logon_id);

    (await this.projectJobService.createProjectJob(this.pJobForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
  async updateJob() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    //this.pJobForm.value['requester_id'] = loggedInUser.logon_id;
    this.pJobForm.controls['requester_id'].setValue(loggedInUser.logon_id);

    (await this.projectJobService.updateProjectJob(this.pJobForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
  async initializeForm(pJobInfo: any) {
    if (pJobInfo != null) {
      this.pJobForm = new FormGroup({
        job_id: new FormControl(pJobInfo?.job_id),
        site_id: new FormControl(pJobInfo?.site_id),
        project_id: new FormControl(pJobInfo?.project_id),
        job_code: new FormControl({value: pJobInfo?.job_code, disabled:this.isEditMode}, [Validators.required]),
        job_title: new FormControl(pJobInfo?.job_title, [Validators.required]),
        is_active: new FormControl({value: pJobInfo?.is_active, disabled: pJobInfo?.is_active }, [Validators.required]),        
        requester_id: new FormControl('')
      });
    }
    else {
      this.pJobForm = new FormGroup({
        job_id: new FormControl(''),
        site_id: new FormControl(''),
        project_id: new FormControl(''),
        job_code: new FormControl({value: '', disabled:this.isEditMode}, [Validators.required]),
        job_title: new FormControl('', [Validators.required]),
        is_active: new FormControl(true, [Validators.required]),       
        requester_id: new FormControl('')
      });
    }
  }
}
