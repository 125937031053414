<div class="group-card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Group Information</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="gForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">Group ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtGroupID" class="matInputCls" name="text" formControlName="group_id" maxlength="120">
                            </mat-form-field>
                        </div>
                        
                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix">Group Code</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtGroupCode" name="text" class="matInputCls"
                                        formControlName="group_code" maxlength="10">
                                <mat-error class="text-danger" *ngIf="gForm.controls['group_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                                <mat-error class="text-danger" *ngIf="gForm.controls['group_code'].errors?.['pattern']">
                                    Alphanumeric only
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Group Name</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtGroupName" name="text" class="matInputCls"
                                        formControlName="group_name" maxlength="32">
                                <mat-error class="text-danger" *ngIf="gForm.controls['group_name'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>
                        
                        <div class="item-container">
                            <mat-label class="matLabel">Display Name</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtDisplayName" name="text" class="matInputCls"
                                            formControlName="display_name" maxlength="50">
                            </mat-form-field>
                        </div>
                        
                        <div class="item-container">
                            <mat-label class="matLabel">Project Site</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <mat-select class="matInputCls" formControlName="site_id">
                                    @for (sItem of dModelList; track sItem) {
                                    <mat-option [value]="sItem.site_id">{{sItem.site_code}} -
                                        {{sItem.site_description}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label>
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                id="chkIsActive">
                            </mat-checkbox>
                        </div>
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Admin?</mat-label>
                            <mat-checkbox formControlName="is_admin" class="matInputCls chkMatCheckbox"
                                id="chkIsAdmin">
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>