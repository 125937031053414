import { Component, computed, effect, Input, input, signal } from '@angular/core';
import { MaterialModule } from '../../core/material-module';
import { RouterModule } from '@angular/router';
import { MenuItem } from '../custom-sidenav/custom-sidenav.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { computeMsgId } from '@angular/compiler';

@Component({
  selector: 'app-menu-item',
  standalone: true,
  animations: [
    trigger('expandContractMenu', [
      transition(':enter', [
        style({ opacity: 0, height: '0px' }),
        animate('500ms ease-in-out', style({ opacity: 1, height: '*' }))
      ]),
      transition(':leave', [
        animate('500ms ease-in-out', style({ opacity: 0, height: '0px' }))
      ])
    ])
  ],
  imports: [MaterialModule, RouterModule],
  template: `
            <a 
              mat-list-item
              class="menu-item"
              [routerLink]="item().route"
              (click)="toggleNested(item().route)"
              routerLinkActive="selected-menu-item"
              #rla="routerLinkActive"
              [activated]="rla.isActive">
                <!-- <mat-icon matListItemIcon>{{item().icon}}</mat-icon> -->
                <span matListItemTitle>{{item().menu_name}}</span>

                @if(item().children) {
                <span matListItemMeta>
                  @if(nestedMenuOpen()) {
                    <mat-icon>expand_less</mat-icon>
                  } @else {
                    <mat-icon>expand_more</mat-icon>
                  }
                  </span>
                }
            </a>

            @if(item().children && nestedMenuOpen()) {
              <div @expandContractMenu>
                @for (subItem of item().children; track subItem.route) {
                  <app-menu-item [item]="subItem" [snav]="snav"/>
                }
              </div>
            }
  `,
  styles: [`
    .menu-item {
      border-left: 5px solid;
      border-left-color: rgba(0,0,0,0);
    }
    .selected-menu-item {
      border-left-color: blue;
      background: rgba(0,0,0,0.05);
    }
    :host {
      --mat-list-active-indicator-shape: 0px;
    }
    .indented {
      /* --mat-list-list-item-leading-icon-start-space: 48px; */
    }
`
  ]
})
export class MenuItemComponent {
  // @Input() item: [] = [];
  @Input() snav;
  item = input.required<MenuItem>();
  nestedMenuOpen = signal(false);
  routeHistory = input('');
  logRoutes = effect(() => console.log("history:", this.routeHistory()));

  // level = computed(() => this.routeHistory().split(''))
  toggleNested(route) {
    if(route!=null && route!=undefined && route!='#' && route!=""){
      this.snav.toggle();
    }

    if (!this.item().children) {
      return;
    }
    this.nestedMenuOpen.set(!this.nestedMenuOpen());
  }
}
