<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>
<div class="centered-outer-div">
    <dx-tree-list id="treeProjectList" [dataSource]="dModelList" keyExpr="project_id" parentIdExpr="parent_project_id" [showRowLines]="true"
        [showBorders]="true" class="centered-inner-div" (onSelectionChanged)="onSelectionChanged($event)" [rowAlternationEnabled]="true">
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

        <dxi-column dataField="" [width]="70"></dxi-column>
        <dxi-column type="buttons" [width]="50" caption="">
            <dxi-button hint="Edit selected Project" icon="edit" [visible]=true [disabled]=false
                [onClick]="RouteToDetails"></dxi-button>
            <!-- <dxi-button hint="Delete selected Project" icon="trash" [visible]=true [disabled]=false
            [onClick]="DeleteProjectEvent"></dxi-button> -->
        </dxi-column>

        <dxi-column dataField="project_id" [width]="100" caption="Project ID" [visible]="false"></dxi-column>
        <dxi-column dataField="site_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
        <dxi-column dataField="site_code" [width]="100" caption="Site Code" [visible]="false"></dxi-column>
        <dxi-column dataField="project_code" [width]="150" caption="Project Code"></dxi-column>
        <dxi-column dataField="project_title" [width]="200" caption="Project Title"></dxi-column>
        <dxi-column dataField="start_date" [width]="200" caption="Start Date" dataType="date" format="MM/dd/yyyy"></dxi-column>
        <dxi-column dataField="parent_project_id" [width]="200" caption="Parent Project ID" [visible]="false"></dxi-column>
        <dxi-column dataField="parent_project_code" [width]="200" caption="Parent Project Code"></dxi-column>
        <dxi-column dataField="parent_project_title" [width]="200" caption="Parent Project Title"></dxi-column>
        <dxi-column dataField="is_active" [width]="90" caption="Is Active?" [visible]="true"></dxi-column>

        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
            [showNavigationButtons]="true">
        </dxo-pager>
        <dxo-paging [pageSize]="10" [pageIndex]="0">
        </dxo-paging>
    </dx-tree-list>
</div>