import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { BehaviorSubject, catchError, throwError } from "rxjs";
import { utility } from "../util";

@Injectable({
    providedIn: 'root'
})

export class WeldService {
    private apiBaseUrl: string;
    private selectedInfo = new BehaviorSubject(null);
    getSelectedInfo = this.selectedInfo.asObservable();
    private environmentConfig = utility.getEnvironmentData();

    constructor(private httpClient: HttpClient) {
        //this.apiBaseUrl = environment.apiBase;
        this.apiBaseUrl = this.environmentConfig.weldApiBase;
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    setSelectedInfo(paramData: any) {
        this.selectedInfo.next(paramData);
    }

    public async getAllResourceList(logon_id: any, site_id: any) {
        var apiMethod = "resourceinfo/GetAll";
        var param = "?logon_id=" + logon_id + "&site_id=" + site_id ;
        var url = this.apiBaseUrl + apiMethod + param;
        return this.httpClient.get(url).pipe(
          catchError(this.errorHandler)
        );
    }

    public async getSelectedResource(site_id: any, is_active: any) {
        var apiMethod = "resourceinfo/detail";
        var param = "?site_id=" + site_id + "&is_active=" + is_active;
        var url = (this.apiBaseUrl + apiMethod) + param;
        return this.httpClient.get(url).pipe(
          catchError(this.errorHandler)
        );
    }

    public async createResource(post: any) {
        var apiMethod = "resourceinfo/Create";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.post(url, JSON.stringify(post), this.httpOptions)
        .pipe(
            catchError(this.errorHandler)
        );
    }

    public async updateResource(put: any) {
        var apiMethod = "resourceinfo/Update";
        var url = (this.apiBaseUrl + apiMethod)
        return this.httpClient.put(url, JSON.stringify(put), this.httpOptions)
        .pipe(
            catchError(this.errorHandler)
        );
    }

    public async deleteResource(del: any) {
        var apiMethod = "resourceinfo/Delete";
        var url = (this.apiBaseUrl + apiMethod);
        return this.httpClient.put(url, JSON.stringify(del), this.httpOptions)
        .pipe(
            catchError(this.errorHandler)
        );
    }

    errorHandler(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = error.error.message;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
      }
}