<div class="group-card">
    <mat-card appearance="outlined">
        <mat-card-header>
            <span class="card-title">Site Information</span>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="sForm" novalidate class="form">
                <div class="flex-container">
                    <div class="side-container">
                        <div class="item-container disNone">
                            <mat-label class="matLabel">Site ID</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtSiteID" name="text" class="matInputCls"
                                    formControlName="site_id" maxlength="120">
                            </mat-form-field>
                        </div>

                        <div class="item-container mt-1e">
                            <mat-label class="matLabel asterix mt-1e">Site Code</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtSiteCode" name="text" class="matInputCls"
                                    formControlName="site_code" maxlength="5">
                                <mat-error class="text-danger" *ngIf="sForm.controls['site_code'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel asterix">Site Description</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtSiteDescription" name="text" class="matInputCls"
                                    formControlName="site_description" maxlength="250">
                                <mat-error class="text-danger"
                                    *ngIf="sForm.controls['site_description'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Time Zone</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtTimeZone" name="text" formControlName="time_zone"
                                    maxlength="50" class="matInputCls">
                                <mat-error class="text-danger" *ngIf="sForm.controls['time_zone'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">UOM</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtSite" name="text" formControlName="uom"
                                    maxlength="20" class="matInputCls">
                                <mat-error class="text-danger" *ngIf="sForm.controls['uom'].errors?.['required']">
                                    This is required!
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Base Folder</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtSite" name="text" formControlName="base_folder"
                                    maxlength="20" class="matInputCls">
                            </mat-form-field>
                        </div>

                        <div class="item-container">
                            <mat-label class="matLabel">Global Folder</mat-label>
                            <mat-form-field appearance="outline" class="small">
                                <input matInput type="text" id="txtSite" name="text"
                                    formControlName="global_folder_path" maxlength="20" class="matInputCls">
                            </mat-form-field>
                        </div>
                        <div class="item-container">
                            <mat-label class="chkMatLabel">Is Active?</mat-label>
                            <mat-checkbox formControlName="is_active" class="matInputCls chkMatCheckbox"
                                id="chkIsActive">
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button (click)="backtoList()" class="bckBtn">Back</button>
            <button mat-raised-button color="primary" gap="20px" class="mainBtn" (click)="save()">Save</button>
        </mat-card-actions>
    </mat-card>
</div>