<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>
<div class="centered-outer-div">
    <dx-tree-list id="treeGroupList" [dataSource]="dModelList" keyExpr="id" parentIdExpr="head_id" [showRowLines]="true"
        [showBorders]="true" class="centered-inner-div" (onSelectionChanged)="onSelectionChanged($event)"
        (onEditorPreparing)="editorPreparing($event)" (onCellPrepared)="onCellPrepared($event)"
        [rowAlternationEnabled]="true" width="1200px">
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="multiple" [allowSelectAll]="false" [recursive]="true"  showCheckBoxesMode="always"></dxo-selection>
        <dxi-column dataField="" caption="" [visible]="true" [width]="75"></dxi-column>
        <dxi-column type="buttons" [width]="100" caption="Actions">
            <dxi-button hint="Edit selected Group" icon="edit" [visible]=allowEditing [disabled]=false
                [onClick]="RouteToDetails"></dxi-button>
            <!-- <dxi-button hint="Delete selected Group" icon="trash" [visible]=allowDelete [disabled]=false
                [onClick]="DeleteUserOrGroup"></dxi-button> -->
        </dxi-column>
        <dxi-column dataField="gu_id" caption="GU ID" [visible]="false"></dxi-column>
        <dxi-column dataField="group_id" caption="Group Id" [visible]="false"></dxi-column>
        <dxi-column dataField="site_id" caption="Site Id" [visible]="false"></dxi-column>
        <dxi-column dataField="item_id" caption="Item ID" [visible]="false"></dxi-column>
        <dxi-column dataField="group_code" caption="Group Code"></dxi-column>
        <dxi-column dataField="group_name" caption="Name"></dxi-column>
        <dxi-column dataField="display_name" caption="Display Name"></dxi-column>
        <dxi-column dataField="logon_id" caption="Logon ID"></dxi-column>
        <dxi-column dataField="is_active" caption="Is Active?" [visible]="true"></dxi-column>
        <dxi-column dataField="site_description" caption="Site" [visible]="true"></dxi-column>
    </dx-tree-list>

</div>