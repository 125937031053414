import { MatDialog } from "@angular/material/dialog";
import * as CryptoJS from 'crypto-js';
import { security } from "./security";
import { inject } from "@angular/core";
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from "./environment.config";
export class utility {
  //static unknownCharList = ["\a", "\b", "\f", "\n", "\r", "\t", "\v", "\'", "\\", "\?", "\ ooo"];
  static unknownCharList = ["\t", "\'"];
  static matDialog: MatDialog;
  static activeStatus: any = [
    {
      valueMenber: true,
      displayMember: "Active"
    },
    {
      valueMenber: false,
      displayMember: "Inactive"
    }];

  static extractJsonData(jsonData: any, datasetIndex: number, resDataIndex = 0): any {
    if (jsonData == null) {
      return null;
    }
    if (jsonData.response_data == null) {
      return null;
    }
    if (jsonData.response_data.length <= 0) {
      return null;
    }
    if (jsonData.response_data[resDataIndex].data_set == null) {
      return null;
    }
    if (jsonData.response_data[resDataIndex].data_set.length <= 0) {
      return null;
    }
    return jsonData.response_data[resDataIndex].data_set;
  }
  static responseAlert(jsonData: any) {
    if (jsonData != null && jsonData.response_error_id == "0"
      && jsonData.response_error_message.length > 0) {
      alert(jsonData.response_error_message[0]);
    }
    else if (jsonData != null && jsonData.response_error_id != "0"
      && jsonData.response_error_message.length > 0) {
      alert(jsonData.response_error_message[0]);
    }
    else {
      alert("Error occurred. Please connect helpdesk.");
    }
  }

  // Using a regex to replace all occurrences (case insensitive)
  static removeUnknownCharRegex(str) {
    // If the companySubstrings are never changing, you should declare
    // the regex as a const outside of this function so you don't
    // build a new regex each time you call it
    return str.toString().replace(
      new RegExp(
        this.unknownCharList
          .join('|')
          .replaceAll('.', '\\.'),
        'gi' // g: replace all - i: case insensitive
      ),
      ' '
    ).trim();
  }

  // Looping over the substrings array to replace matches (case sensitive)
  static removeUnknownCharLoop(string) {
    let result = string;
    this.unknownCharList.forEach(
      // use `result.replaceAll(str, '')` to replace **all** occurrences
      // and get the same behavior as the `g` flag in a regex
      //str => result = result.toString().replace(str, ' ')
      str => result
    );
    //return result.trim();
    return result;
  }
  static isEmail(search): boolean {
    var serchfind: boolean;
    var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    serchfind = regexp.test(search);
    console.log(serchfind)
    return serchfind
  }
  // static openModal(popupComponent: any, mDialog:MatDialog) {
  //   // this.matDialog.open(popupComponent, {
  //   //   "width": '600px',
  //   //   "height": '200px',
  //   //   "maxHeight": '600px',
  //   //   "data": "John",
  //   //   "autoFocus": false
  //   // });

  //   const dialogRef = mDialog.open(popupComponent, {
  //     width: '600px',
  //     height: '200px',
  //     maxHeight: '600px',
  //     autoFocus: false,
  //     disableClose: true,
  //     data: {}
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     //this.animal = result;
  //   });
  // }
  // static closeDialog(popupComponent: any): void {
  //   popupComponent.close();
  // }

  static getLoggedInUser(uObject: any, route: any): any {
    var decryptData = security.decrypt(uObject.getItem("loggedInUser"))
    var localUser = JSON.stringify(decryptData);
    var loggedInUser = JSON.parse(JSON.parse(localUser));
    if (loggedInUser == null) {
      route.navigate(['./sessiontimeout']);
    }

    return loggedInUser;
  }
  static getEnvironmentData() {
    var environmentConfig = inject(ENVIRONMENT_CONFIG);
    var decryptConfigData =security.decrypt(environmentConfig.data);
    var iResultData = JSON.parse(decryptConfigData);
    return iResultData;
  }
}