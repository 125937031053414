import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from '../../../../core/devExtreme.module';
import { ToolbarButtonsComponent } from '../../../../header/toolbar-buttons/toolbar-buttons.component';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ToolbarItem } from '../../../../header/toolbar-buttons/toolbar-item';
import { utility } from '../../../../util';
import { MaterialTypeService } from '../../../../APIServices/master/weld/materialType.service';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-material-type-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent, CommonModule],
  templateUrl: './material-type-list.component.html',
  styleUrl: './material-type-list.component.scss'
})
export class MaterialTypeListComponent implements OnInit {
  materialTypeModelList: any;
  title: any = "Material Type";
  selectedInfo: any;
  loggedInUser: any;

  constructor(
    private materialTypeService: MaterialTypeService,
    private router: Router
  ) {}

  async ngOnInit() {   
    this.loggedInUser = utility.getLoggedInUser(localStorage, this.router);
    await this.Index();
  }

  public async Index() {
  
    (await this.materialTypeService.getAllMaterialType()).subscribe((apiResponse: any) => {
      this.materialTypeModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  onSelectionChanged(e){
    this.selectedInfo = e.selectedRowsData;
    this.materialTypeService.setSelectedInfo(this.selectedInfo);
  }

  routeToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./materialtypedetails']);
    }
    var selectedID = e.row?.data.material_type_id;
    let params = {
      material_type_id: selectedID
    };
    this.router.navigate(['./materialtypedetails'], { queryParams: params });
    var uModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
      uModel.site_id = this.loggedInUser.site_id;
      uModel.site_code = this.loggedInUser.site_code;
      uModel.site_description = this.loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      uModel = <any>JSON.parse(localUser);
    }
      this.materialTypeService.setSelectedInfo(uModel);
      e.event?.preventDefault();
  };

  createMaterialType(){
    this.materialTypeService.setSelectedInfo(null);
    this.router.navigate(['./materialtypedetails']);
  }

  async deactivateMaterialType(){
    if (this.selectedInfo == null || this.selectedInfo == undefined ||
      this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select a Material Type record");
      return;
    }

    if (!confirm("Are you sure to Inactivate selected Material Type?")) {
      return;
    }
    let params = {
      material_type_list: this.selectedInfo
    };

    (await this.materialTypeService.deleteMaterialType(params)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.Index();
    });
  }

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Type', path: '', iconName: '', action: () => this.createMaterialType(), hidden: false },
    { label: 'Inactivate Type', path: '', iconName: '', action: () => this.deactivateMaterialType(), hidden: false },
  ];
}
