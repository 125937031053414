import { Component, Input, OnInit, signal } from '@angular/core';
import { MaterialModule } from '../../core/material-module';
import { RouterModule } from '@angular/router';
import { MenuItemComponent } from "../menu-item/menu-item.component";

export type MenuItem = {
  display_name?: string;
  isSideNavItem?: string;
  isTopNavItem?: string;
  menu_id?: number;
  menu_name?: string;
  module_code?: string;
  module_name?: string;
  parent_id?: number;
  route?: string;
  children?: MenuItem[];
  icon?: string;
}

@Component({
  selector: 'app-custom-sidenav',
  standalone: true,
  imports: [MaterialModule, MenuItemComponent, RouterModule],
  template: `
            <mat-nav-list>
              @for (item of menuItem; track item.menu_name) {
                <app-menu-item [item] ="item" [snav]="snav"/>
              }
            </mat-nav-list>
            `,
  styles: [`
        .menu-item {
          border-left: 5px solid;
          border-left-color: rgba(0,0,0,0);
        }
        .selected-menu-item {
          border-left-color: blue;
          background: rgba(0,0,0,0.05);
        }
    `
  ]

})

export class CustomSidenavComponent {
  @Input() menuItem;
  @Input() snav;

  /*  menuItem = [
    {
      display_name: "Security",
      icon: "security",
      subItems: [
        {
          display_name: "User Management",
          route: "userlist",
          icon: "supervised_user_circle",
          subItems: [{
            display_name: "User 1",
            route: "grouplist",
            icon: "group",
            subItems: [{
              display_name: "User 1.1",
              route: "grouplist",
              icon: "group",
              subItems: [{
                display_name: "User 1.1.1",
                route: "grouplist",
                icon: "group"
              }]
            }]
          },
          {
            display_name: "user 2",
            route: "grouplist",
            icon: "group",
          },
        ]
        },
        {
          display_name: "Group Management",
          route: "grouplist",
          icon: "group"
        },
        {
          display_name: "Access Management",
          route: "groupaccess",
          icon: "personal_video"
        }
      ]
    },
    {
      display_name: "Global",
      icon: "public",
      subItems: [
        {
          display_name: "Project Management",
          subItems: [
            {
              display_name: "Project Info",
              route: "projectlist"
            }
          ]
        },
        {
          display_name: "Site Management",
          route: "sitelist"
        },
      ]
    }
   ] */
  /*  menuItem = [
    {
        menu_id: 1,
        menu_name: "Security",
        parent_id: 0,
        children: [
            {
                menu_id: 2,
                menu_name: "User Management",
                parent_id: 1
            },
            {
                menu_id: 3,
                menu_name: "Group Management",
                parent_id: 1
            },
            {
                menu_id: 4,
                menu_name: "Access Management",
                parent_id: 1
            }
        ]
    },
    {
        menu_id: 5,
        menu_name: "Global",
        parent_id: 0,
        children: [
            {
                menu_id: 6,
                menu_name: "Project Management",
                parent_id: 5,
                children: [
                    {
                        menu_id: 9,
                        menu_name: "Project Info",
                        parent_id: 6
                    },
                    {
                        menu_id: 10,
                        menu_name: "Project Setup",
                        parent_id: 6
                    }
                ]
            },
            {
                menu_id: 7,
                menu_name: "Site Management",
                parent_id: 5
            }
        ]
    }
]; */
  // menuItems = signal<MenuItem[]>(this.menuItem);


}
