<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div class="centered-outer-div">
    <dx-data-grid id="gridSiteList" [dataSource]="dModelList" keyExpr="location_id" [rowAlternationEnabled]="true"
        class="centered-inner-div" (onSelectionChanged)="onSelectionChanged($event)" 
        [showBorders]="true" [showColumnLines]="true" [showRowLines]="true">

        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

        <dxi-column type="buttons" [width]="80" caption="Actions">
            <dxi-button hint="Edit selected Work Location" icon="edit" [visible]=true [disabled]=false
                [onClick]="RouteToDetails"></dxi-button>
        </dxi-column>

        <dxi-column dataField="location_id" [width]="100" caption="Location ID" [visible]="false"></dxi-column>
        <dxi-column dataField="location_code" [width]="100" caption="Code"></dxi-column>
        <dxi-column dataField="location_description" [width]="200" caption="Description"></dxi-column>
        <dxi-column dataField="glb_location_detail" [width]="200" caption="Global Location"></dxi-column>
        <!-- <dxi-column dataField="time_zone" [width]="200" caption="Time Zone"></dxi-column> -->
        <dxi-column dataField="is_active" [width]="70" caption="Is Active"></dxi-column>
    </dx-data-grid>
</div>