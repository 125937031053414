<app-toolbar-buttons [items]="toolbarItems" [title]="title"></app-toolbar-buttons>

<div>
    <table>
        <tbody>
            <tr>
                <td style="width:10%">
                    <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
                        <mat-label>Select Site</mat-label>
                        <mat-select [(ngModel)]="selectedsite" (selectionChange)="filterBySite()">
                            <ng-container>
                                @for (Item of sModelList; track Item) {
                                <mat-option [value]="Item.site_id">{{Item.site_description}}</mat-option>
                                }
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td style="width:10%; padding-left: 10px;">
                    <mat-form-field appearance="outline" class="small clsFloatingField fltrElement">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="selectedStatusFilter" (selectionChange)="filterBySite()">
                            <ng-container>
                                @for (Item of dStatusFilter; track Item) {
                                <mat-option [value]="Item.valueMenber">{{Item.displayMember}}</mat-option>
                                }
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
<table border="1">
    <tbody>
        <tr>
            <td>
                <div class="centered-outer-div">
                    <dx-data-grid id="gridUserList" #dxUserList [dataSource]="uModelList"
                        [allowColumnReordering]="true" class="centered-inner-div" [showBorders]="true"
                        [showColumnLines]="true" [showRowLines]="true" [rowAlternationEnabled]="true" 
                        (onSelectionChanged)="onSelectionChanged($event)" width="1200px">
                        <dxo-selection mode="multiple" showCheckBoxesMode="always">
                        </dxo-selection>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxi-column type="buttons" [width]="50">
                            <dxi-button hint="Edit selected User" icon="edit" [visible]=true [disabled]=false
                                [onClick]="RouteToUserDetails"></dxi-button>
                        </dxi-column>

                        <dxi-column dataField="user_id" [width]="100" caption="User ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="site_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="site_code" [width]="100" caption="Site Code"
                            [visible]="false"></dxi-column>
                        <dxi-column dataField="merged_group_id" [width]="100" caption="Merged Group ID"
                            [visible]="false"></dxi-column>

                        <dxi-column dataField="logon_id" [width]="150" cssClass="WrappedColumnClass" caption="Logon ID"></dxi-column>
                        <dxi-column dataField="full_name" [width]="200" cssClass="WrappedColumnClass" caption="Full Name"></dxi-column>
                        <dxi-column dataField="merged_group_name" [width]="250" cssClass="WrappedColumnClass" caption="Assigned Groups"></dxi-column>
                        <dxi-column dataField="user_email" [width]="150" cssClass="WrappedColumnClass" caption="EMail"></dxi-column>
                        <dxi-column dataField="is_current" [width]="90" caption="is Current?"
                            [visible]="true" cssClass="dxCheckBoxFormat"></dxi-column>
                        <dxi-column dataField="job_function" [width]="100" cssClass="WrappedColumnClass" caption="Job Function"></dxi-column>
                        <dxi-column dataField="address" [width]="200" cssClass="WrappedColumnClass" caption="Address"></dxi-column>
                        <dxi-column dataField="country" [width]="70" cssClass="WrappedColumnClass" caption="Country"></dxi-column>
                        <dxi-column dataField="zipcode" [width]="70" caption="Zipcode"></dxi-column>
                        <dxi-column dataField="is_active" [width]="90" caption="is Active?"
                            [visible]="true" cssClass="dxCheckBoxFormat"></dxi-column>
                        <dxi-column dataField="base_site_description" [width]="120" caption="Base Site" cssClass="dxCheckBoxFormat"></dxi-column>


                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
                            [showNavigationButtons]="true" [visible]="true">
                        </dxo-pager>
                        <dxo-paging [pageSize]="10" [pageIndex]="0">
                        </dxo-paging>
                    </dx-data-grid>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <span class="span-page-title" style="font-weight: bold;">
                    Associated Projects
                </span>
            </td>
        </tr>
        <tr>
            <td>
                <div class="centered-outer-div">
                    <dx-tree-list id="treeProjectList" [dataSource]="dModelList" keyExpr="project_id"
                        parentIdExpr="parent_project_id" [showRowLines]="true" [showBorders]="true"
                        class="centered-inner-div" (onSelectionChanged)="onSelectionChanged($event)" width="1200px">
                        <dxo-filter-row [visible]="true"></dxo-filter-row>

                        <dxi-column dataField="project_id" [width]="100" caption="Project ID"
                            [visible]="false"></dxi-column>
                        <dxi-column dataField="site_id" [width]="100" caption="Site ID" [visible]="false"></dxi-column>
                        <dxi-column dataField="site_code" [width]="100" caption="Site Code"
                            [visible]="false"></dxi-column>
                        <dxi-column dataField="project_code" [width]="125" caption="Project Code"></dxi-column>
                        <dxi-column dataField="project_title" [width]="300" caption="Project Title"></dxi-column>
                        <dxi-column dataField="start_date" [width]="150" caption="Start Date" dataType="date"
                            format="MM/dd/yyyy"></dxi-column>
                        <dxi-column dataField="parent_project_id" [width]="200" caption="Parent Project ID"
                            [visible]="false"></dxi-column>
                        <dxi-column dataField="parent_project_code" [width]="200"
                            caption="Parent Project Code"></dxi-column>
                        <dxi-column dataField="parent_project_title" [width]="300"
                            caption="Parent Project Title"></dxi-column>
                        <dxi-column dataField="is_active" [width]="70" caption="Is Active?"
                            [visible]="true"></dxi-column>

                        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20, 50, 'all']"
                            [showNavigationButtons]="true">
                        </dxo-pager>
                        <dxo-paging [pageSize]="10" [pageIndex]="0">
                        </dxo-paging>
                    </dx-tree-list>
                </div>
            </td>
        </tr>
    </tbody>
</table>