import { Component, Inject, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { MaterialModule } from '../../../core/material-module';
import { groupTreeModel } from '../../../models/groupModel';
import { GroupService } from '../../../APIServices/GroupService';
import { Router } from '@angular/router';
import { utility } from '../../../util';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { AccessService } from '../../../APIServices/AccessService';
import { groupAccessMenuModel } from '../../../models/groupAccessModel';
import { AddEditAccessComponent } from '../add-edit-access/add-edit-access.component';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';


@Component({
  selector: 'app-group-access-mgmt',
  standalone: true,
  imports: [MaterialModule, DevExtremeModule, ToolbarButtonsComponent],
  schemas: [],
  templateUrl: './group-access-mgmt.component.html',
  styleUrl: './group-access-mgmt.component.scss'
})
export class GroupAccessMgmtComponent implements OnInit {
  dModelList: groupTreeModel[] = [];
  dAccessModelList: groupAccessMenuModel[] = [];
  localStorage: any;
  selectedgInfo: any;
  selectedInfo: any;

  title: any = "Group Wise Access Management";

  constructor(@Inject(DOCUMENT) private document: Document, private groupService: GroupService
    , private router: Router
    , private accessService: AccessService
    , private matDialog: MatDialog) {
    this.localStorage = document.defaultView?.localStorage;
  }

  ngOnInit(): void {
    this.Index();
  }

  public async Index() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    //Fetch Group List
    (await this.accessService.getAllGroupList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  async onSelectionChanged(e) {
    if (e.selectedRowsData.length > 0) {
      //Fetch Group Access List
      await this.getGroupAccessList(e.selectedRowsData[0].group_id);
    }
    this.selectedgInfo = e.selectedRowsData
    this.groupService.setSelectedInfo(this.selectedgInfo);
  }
  async getGroupAccessList(group_id: any) {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    //Fetch Group Access List
    (await this.accessService.getGroupAccessList(loggedInUser.logon_id, loggedInUser.site_id, group_id)).subscribe((apiResponse: any) => {
      this.dAccessModelList = utility.extractJsonData(apiResponse, 0);
    });
  }
  onContentReadyHandler(e) {
    e.component.selectRowsByIndexes([0]);
  }
  openDialog(isNew: boolean): void {
    this.accessService.isNewAccess = isNew;
    if (!isNew) {
      if (this.selectedInfo == null || this.selectedInfo == undefined) {
        alert("Please select an access item");
        return;
      }
    }

    const dialogRef = this.matDialog.open(AddEditAccessComponent, {
      width: '350px',
      height: '350px',
      maxHeight: '600px',
      autoFocus: false,
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getGroupAccessList(this.selectedgInfo[0].group_id);
    });
  }
  async onAccessSelectionChanged(e) {
    this.selectedInfo = e.selectedRowsData;
    this.accessService.setSelectedInfo(this.selectedInfo);
  }
  async RemoveAccess() {
    if (this.selectedgInfo != null && this.selectedgInfo.length == undefined) {
      alert("Please select a group");
      return;
    }

    if (this.selectedInfo == null || this.selectedInfo == undefined
      || this.selectedInfo.length == undefined || this.selectedInfo.length == 0) {
      alert("Please select an access item");
      return;
    }

    if (!confirm("Are you sure to delete the selected access from the selected group?")) {
      return;
    }

    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var accessInfoDelete = {
      group_id: this.selectedgInfo[0].group_id,
      site_id: loggedInUser.site_id,
      accessList: this.selectedInfo,
      requester_id: loggedInUser.logon_id
    };

    (await this.accessService.deleteGroupAccess(accessInfoDelete)).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
      this.getGroupAccessList(this.selectedgInfo[0].group_id);
    });

  }
  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    console.log(e.row?.data);
    if (e.row?.data == null) {
      alert("Please select an access item");
      return;
    }
    this.selectedInfo = e.row?.data;
    this.accessService.setSelectedInfo(this.selectedInfo);

    this.openDialog(false);
    e.event?.preventDefault();
  };

  public toolbarItems: ToolbarItem[] = [
    { label: 'Assign Access', path: '', iconName: '', action: () => this.openDialog(true), hidden: false },
    { label: 'Remove Access', path: '', iconName: '', action: () => this.RemoveAccess(), hidden: false },
  ];
}
