import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialTypeService } from '../../../../APIServices/master/weld/materialType.service';
import { ActivatedRoute, Router } from '@angular/router';
import { utility } from '../../../../util';

@Component({
  selector: 'app-material-type-details',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule],
  templateUrl: './material-type-details.component.html',
  styleUrl: './material-type-details.component.scss'
})
export class MaterialTypeDetailsComponent implements OnInit{
  materialTypeForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private materialTypeService: MaterialTypeService
) { 
    this.initializeForm(null); 
  }

  async ngOnInit() {
    await this.materialTypeService.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {
      this.queryParams = this.route.snapshot.queryParams['material_type_id'];
      
      if (this.queryParams != undefined) {
        this.bEditMode = true;
        
        (await this.materialTypeService.getMaterialTypeDetails(this.queryParams)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];
          
          this.materialTypeService.setSelectedInfo(this.selectedInfo);
          this.initializeForm(this.selectedInfo);
        });
      }
      else {        
          this.bEditMode = false;        
      }
    }
    else {
      this.bEditMode = true;
      await this.initializeForm(this.selectedInfo);
    }
  }

  async initializeForm(mInfo: any) {
    if (mInfo != null){        
      this.materialTypeForm = new FormGroup(
        {
          material_type_id: new FormControl(mInfo?.material_type_id ),
          material_type_code: new FormControl({ value: mInfo?.material_type_code , disabled: this.bEditMode }, [Validators.required]),
          material_type_title: new FormControl(mInfo?.material_type_title, [Validators.required]),
          is_active: new FormControl({value: mInfo?.is_active, disabled: mInfo?.is_active})
        }
      )      
    }
    else{
      this.materialTypeForm = new FormGroup(
        {
          material_type_id: new FormControl(null),
          material_type_code: new FormControl({ value: '', disabled: this.bEditMode }, [Validators.required]),
          material_type_title: new FormControl({ value: '', disabled: false }, [Validators.required]),
          is_active: new FormControl({ value: true, disabled: true })
        }
      )
    }
  }

  backtoList(){
    this.router.navigate(['./materialtypelist']); 
  }

  async save(){
    this.materialTypeForm.markAllAsTouched();
    const isFormValid = this.materialTypeForm.valid;
    if (!isFormValid) {
      return;
    }
    if (this.materialTypeForm.value['material_type_id'] == null) {
      this.saveMaterialType();
    } else {
      this.updateMaterialType();
    }
  }

  async saveMaterialType() {
    (await this.materialTypeService.saveMaterialType(this.materialTypeForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }

  async updateMaterialType() {
    (await this.materialTypeService.updateMaterialType(this.materialTypeForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
}
