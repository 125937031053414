import { Component, OnInit } from '@angular/core';
import { DevExtremeModule } from '../../../core/devExtreme.module';
import { MsalService } from '@azure/msal-angular';
import { SiteService } from '../../../APIServices/SiteService';
import { utility } from '../../../util';
import { siteModel } from '../../../models/siteModel';
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { Router } from '@angular/router';
import { ToolbarButtonsComponent } from '../../../header/toolbar-buttons/toolbar-buttons.component';
import { ToolbarItem } from '../../../header/toolbar-buttons/toolbar-item';

@Component({
  selector: 'app-site-list',
  standalone: true,
  imports: [DevExtremeModule, ToolbarButtonsComponent],
  templateUrl: './site-list.component.html',
  styleUrl: './site-list.component.scss'
})
export class SiteListComponent implements OnInit {
  dModelList: any[] = []
  title: any = "Site Management";

  constructor(private siteService: SiteService, private router: Router) {
  }

  ngOnInit(): void {
    this.Index();
  }

  public async Index() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    (await this.siteService.getAllSiteList(loggedInUser.logon_id, loggedInUser.site_id)).subscribe((apiResponse: any) => {
      this.dModelList = utility.extractJsonData(apiResponse, 0);
    });
  }

  RouteToDetails = (e: DxDataGridTypes.ColumnButtonClickEvent) => {
    if (e.row?.data == null) {
      this.router.navigate(['./sitedetails']);
    }

    var selectedID = e.row?.data.site_id;

    let params = {
      site_id: selectedID
    };
    this.router.navigate(['./sitedetails'], { queryParams: params });
    var dModel;
    if (e.row?.data.site_id == undefined || e.row?.data.site_id == null) {

      var localUser = JSON.stringify(e.row?.data);
      dModel = <any>JSON.parse(localUser);

      var localUser = JSON.stringify(localStorage.getItem('loggedInUser'));
      var loggedInUser = JSON.parse(JSON.parse(localUser));
      dModel.site_id = loggedInUser.site_id;
      dModel.site_code = loggedInUser.site_code;
      dModel.site_description = loggedInUser.site_description;
    }
    else {
      var localUser = JSON.stringify(e.row?.data);
      dModel = <any>JSON.parse(localUser);
    }
    this.siteService.setSelectedInfo(dModel);
    e.event?.preventDefault();
  }

  RouteToCreate() {
    var loggedInUser = utility.getLoggedInUser(localStorage, this.router);

    var dModel;
    // if (loggedInUser.site_id == 1 || loggedInUser.site_code == 'GLB') {
    //   dModel = null;
    // }
    // else {
    //   dModel = {
    //     site_id: loggedInUser.site_id,
    //     site_code: loggedInUser.site_code,
    //     site_description: loggedInUser.site_description
    //   }
    // }
    this.siteService.setSelectedInfo(dModel);
    this.router.navigate(['./sitedetails']);
  }

  public toolbarItems: ToolbarItem[] = [
    { label: 'Add Site', path: '', iconName: '', action: () => this.RouteToCreate(), hidden: false }
  ];

}
