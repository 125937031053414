import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '../../../../../core/material-module';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkLocationService } from '../../../../../APIServices/master/weld/work-location.service';
import { utility } from '../../../../../util';


@Component({
  selector: 'app-work-location-detail',
  standalone: true,
  imports: [MaterialModule, ReactiveFormsModule, CommonModule],
  templateUrl: './work-location-detail.component.html',
  styleUrl: './work-location-detail.component.scss'
})
export class WorkLocationDetailComponent  implements OnInit {
  wForm: FormGroup;
  selectedInfo: any;
  queryParams: any;
  bEditMode: boolean = false;
  lookupglblocationlist: any;
  slookupGlbLocList: any;

  /**
   *
   */
  constructor(private router: Router,
            private activatedRoute: ActivatedRoute,
            private workLocationService: WorkLocationService
  ) {
    this.initializeForm(null)
  }

  async ngOnInit() {
    await this.populateLookUp();
    await this.Index();
  }

  async Index(){
    await this.workLocationService.getSelectedInfo$.subscribe(x =>
      this.selectedInfo = x);

    if (this.selectedInfo == null) {
      this.queryParams = this.activatedRoute.snapshot.queryParams['location_id'];
      
      if (this.queryParams != undefined) {
        this.bEditMode = true;
        
        (await this.workLocationService.getWorkLocationDetail(this.queryParams)).subscribe((apiResponse: any) => {
          const jExtract = utility.extractJsonData(apiResponse, 0);
          this.selectedInfo = jExtract[0];
          
          this.workLocationService.setSelectedInfo(this.selectedInfo);
          this.initializeForm(this.selectedInfo);
        });
      }
      else {        
          this.bEditMode = false;        
      }
    }
    else {
      this.bEditMode = true;
      await this.initializeForm(this.selectedInfo);
    }
  }

  initializeForm(wInfo: any){
    if (wInfo != null){        
      this.wForm = new FormGroup(
        {
          location_id: new FormControl(wInfo?.location_id),
          location_code: new FormControl({ value: wInfo?.location_code , disabled: this.bEditMode }, [Validators.required]),
          location_description: new FormControl(wInfo?.location_description, [Validators.required]),
          glb_location_id: new FormControl(wInfo?.glb_location_id),
          is_active: new FormControl({value: wInfo?.is_active , disabled: wInfo?.is_active })
        }
      )      
    }
    else{
      this.wForm = new FormGroup(
        {
          location_id: new FormControl(null),
          location_code: new FormControl({ value: '', disabled: this.bEditMode }, [Validators.required]),
          location_description: new FormControl({ value: '', disabled: false }, [Validators.required]),
          glb_location_id: new FormControl(null),
          is_active: new FormControl({ value: true, disabled: true })
        }
      )
    }
  }

  async populateLookUp(){
    (await this.workLocationService.GetGlbWorkLocation()).subscribe((apiResponse: any) => {
      this.slookupGlbLocList = utility.extractJsonData(apiResponse, 0);
      // if (this.userService.selectedUserInfo.logon_id != undefined) 
      //   this.selectedUserInfo.site_id = this.userService.selectedUserInfo.site_id;
    });
  }

  backtoList(){
    this.router.navigate(['./worklocationlist']);
  }

  save(){
    this.wForm.markAllAsTouched();
    const isFormValid = this.wForm.valid;
    if (!isFormValid) {
      return;
    }
    if (this.wForm.value['location_id'] == null) {
      this.createItem();
    } else {
      this.updateItem();
    }
  }

  async createItem() {   

    (await this.workLocationService.CreateWorkLocation(this.wForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
  async updateItem() {

    (await this.workLocationService.UpdateWorkLocation(this.wForm.getRawValue())).subscribe((apiResponse: any) => {
      utility.responseAlert(apiResponse);
    });
  }
}
