import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule, Routes } from '@angular/router';
import { ProjectListComponent } from './modules/project/project-list/project-list.component';
import { ProjectDetailsComponent } from './modules/project/project-details/project-details.component';
import { HomePageComponent } from './home/home-page/home-page.component';
import { TopNavBarComponent } from './header/top-nav-bar/top-nav-bar.component';
import { UserListComponent } from './modules/user/user-list/user-list.component';
import { UserDetailsComponent } from './modules/user/user-details/user-details.component';
import { SessionTimeoutComponent } from './home/session-timeout/session-timeout.component';
import { GroupListComponent } from './modules/group/group-list/group-list.component';
import { GroupDetailsComponent } from './modules/group/group-details/group-details.component';
import { GroupAccessMgmtComponent } from './modules/access/group-access-mgmt/group-access-mgmt.component';
import { SiteListComponent } from './modules/site/site-list/site-list.component';
import { SiteDetailsComponent } from './modules/site/site-details/site-details.component';
import { ProjectSetupComponent } from './modules/project/project-setup/project-setup.component';
import { AboutPageComponent } from './home/about-page/about-page.component';
import { FaqPageComponent } from './home/faq-page/faq-page.component';
import { NotificationPageComponent } from './home/notification-page/notification-page.component';
import { DataEncryptionComponent } from './core/data-encryption/data-encryption.component';
import { EmployeeTypeListComponent } from './modules/master/weld/employeetype/employee-type-list/employee-type-list.component';
import { EmployeeTypeDetailComponent } from './modules/master/weld/employeetype/employee-type-detail/employee-type-detail.component';
import { WorklocationListComponent } from './modules/master/weld/worklocation/worklocation-list/worklocation-list.component';
import { WorkLocationDetailComponent } from './modules/master/weld/worklocation/work-location-detail/work-location-detail.component';
import { ResourceListComponent } from './modules/resource/resource-list/resource-list.component';
import { ResourceDetailsComponent } from './modules/resource/resource-details/resource-details.component';
import { MaterialTypeListComponent } from './modules/master/weld/material-type-list/material-type-list.component';
import { MaterialTypeDetailsComponent } from './modules/master/weld/material-type-details/material-type-details.component';


export const routes: Routes = [
  { path: '', component: HomePageComponent, pathMatch: 'full' },
  { path: 'projectlist', component: ProjectListComponent , pathMatch: 'full'},
  { path: 'projectdetails', component: ProjectDetailsComponent, pathMatch: 'full' },
  { path: 'userlist', component: UserListComponent, pathMatch: 'full' },
  { path: 'userdetails', component: UserDetailsComponent, pathMatch: 'full' },
  { path: 'sessiontimeout', component: SessionTimeoutComponent, pathMatch: 'full' },
  { path: 'grouplist', component: GroupListComponent, pathMatch: 'full' },
  { path: 'groupdetails', component: GroupDetailsComponent, pathMatch: 'full' },
  { path: 'groupaccess', component: GroupAccessMgmtComponent, pathMatch: 'full' },
  { path: 'sitelist', component: SiteListComponent, pathMatch: 'full' },
  { path: 'sitedetails', component: SiteDetailsComponent, pathMatch: 'full' },
  { path: 'projectsetup', component: ProjectSetupComponent, pathMatch: 'full' },
  { path: 'aboutpage', component: AboutPageComponent, pathMatch: 'full' },
  { path: 'faqpage', component: FaqPageComponent, pathMatch: 'full' },
  { path: 'notificationpage', component: NotificationPageComponent, pathMatch: 'full' },
  { path: 'resourcelist', component: ResourceListComponent, pathMatch: 'full' },
  { path: 'resourcedetails', component: ResourceDetailsComponent, pathMatch: 'full' },
  { path: 'dataencryption', component: DataEncryptionComponent, pathMatch: 'full' },
  { path: 'employeetypelist', component: EmployeeTypeListComponent, pathMatch: 'full' },
  { path: 'employeetypedetails', component: EmployeeTypeDetailComponent, pathMatch: 'full' },
  { path: 'worklocationlist', component: WorklocationListComponent, pathMatch: 'full' },
  { path: 'worklocationdetails', component: WorkLocationDetailComponent, pathMatch: 'full' },
  { path: 'materialtypelist', component: MaterialTypeListComponent, pathMatch: 'full' },
  { path: 'materialtypedetails', component: MaterialTypeDetailsComponent, pathMatch: 'full' },

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      routes,
      { enableTracing: true } // <--Debugging purpose only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
